import Main from "./components/Main";
import React from "react";

function App() {
  return (
    <div className="">
        <header>
            <h1>Кошелек</h1>
            <h2>Калькулятор расходов</h2>
        </header>
      <Main/>
    </div>
  );
}

export default App;
