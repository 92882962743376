import React from 'react';
import ReactDOM from 'react-dom';
import './normalize.css';
import './style.css';
import App from './App';

//Pw0utg4wV6hyiVBQ

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
